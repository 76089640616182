const verifyCaptcha = (callback: (token?: string) => void, siteKey?: string) => {
  // If grecaptcha wasn't loaded, e.g. in tests,
  // skip the invokation to retrieve the token;
  // or if no site key.
  if (typeof grecaptcha === 'undefined' || !siteKey) {
    callback()
  } else {
    // https://developers.google.com/recaptcha/docs/v3
    grecaptcha.ready(function () {
      grecaptcha.execute(siteKey).then(async function (token: string) {
        callback(token)
      })
    })
  }
}
export const GoogleCaptchaV3Service = { verifyCaptcha }
